<template>
	<div class="mb-6">
		<template v-if="item.type === 'offer'">
			<div class="mb-3 text-base font-bold text-brown">
				{{ item.offer.name }}
				<span
					v-if="item.amount > 1"
					class="ml-1 text-dark-blue"
				>
					x{{ item.amount }}
				</span>
			</div>
			<div
				v-for="(product, index) in item.items"
				:key="index"
				class="mb-3 text-sm"
			>
				<div class="font-bold text-[13px]">{{ product.name }}</div>
				<div
					v-for="(ingredient, index) in getIngredientDifferences(product)"
					:key="index"
					class="mt-1 ml-2 text-[13px]"
				>
					{{ ingredient }}
				</div>
			</div>
		</template>

		<template v-if="item.type === 'product'">
			<div class="mb-3 text-base font-bold text-brown">
				{{ item.product.name }}
				<span
					v-if="item.amount > 1"
					class="ml-1 text-dark-blue"
				>
					x{{ item.amount }}
				</span>
			</div>
			<div
				v-for="(ingredient, index) in getIngredientDifferences(item.product)"
				:key="index"
				class="mt-1 ml-2 text-[13px]"
			>
				{{ ingredient }}
			</div>
		</template>
	</div>
</template>

<script>
export default {
	name: 'OrderItem',
	props: {
		item: Object,
		getIngredientDifferences: Function,
	},
};
</script>
