<template>
	<div class="mb-8 flex flex-col items-center justify-center gap-4 sm:mb-0">
		<a
			href="#"
			class="grid h-12 w-12 flex-shrink-0 transform place-items-center rounded-full bg-brown text-white active:bg-brown-shade sm:h-12 sm:w-12"
			@click.prevent="increment"
			:class="{ 'pointer-events-none hidden': !editable }"
		>
			<svg
				width="18"
				height="8"
				viewBox="0 0 18 8"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M17 6.81818L9 1L0.999999 6.81818"
					stroke="currentColor"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</a>

		<!-- Display the formatted time -->
		<div class="w-32 text-center text-xl font-medium sm:text-2xl">{{ formattedValue }}</div>

		<a
			href="#"
			class="grid h-12 w-12 flex-shrink-0 rotate-180 transform place-items-center rounded-full bg-brown text-white active:bg-brown-shade sm:h-12 sm:w-12"
			@click.prevent="decrement"
			:class="{ 'pointer-events-none hidden': !editable }"
		>
			<svg
				width="18"
				height="8"
				viewBox="0 0 18 8"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M17 6.81818L9 1L0.999999 6.81818"
					stroke="currentColor"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</a>
	</div>
</template>

<script>
export default {
	name: 'TimeStepper',

	emits: ['update:modelValue'],

	props: {
		modelValue: {
			type: Number,
			default: 15,
		},
		step: {
			type: Number,
			default: 5,
		},
		editable: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		formattedValue() {
			return this.formatTwoDigits(this.modelValue);
		},
	},

	methods: {
		increment() {
			const newValue = this.modelValue + this.step;
			this.$emit('update:modelValue', newValue);
		},

		decrement() {
			const newValue = this.modelValue - this.step;
			this.$emit('update:modelValue', newValue);
		},

		formatTwoDigits(value) {
			return value < 10 ? `0${value}` : value.toString();
		},
	},
};
</script>
