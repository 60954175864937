<template>
	<!-- Critical Order -->
	<a
		href="#"
		v-if="isCritical"
		@click.prevent="$emit('click', order)"
		class="rounded-xl bg-red p-5 text-white active:bg-red-shade"
	>
		<div class="mb-1 flex justify-between gap-3 font-medium">
			<div class="w-full">
				<span class="mr-2 break-words">{{ order.name }}</span>
				<span class="whitespace-nowrap font-normal">{{ removeCountryCode(order.mobile) }}</span>
			</div>
			<div class="flex-shrink-0">{{ time }}</div>
		</div>

		<div class="text-sm">{{ items }}</div>
	</a>

	<!-- Normal Order -->
	<a
		href="#"
		v-else
		@click.prevent="$emit('click', order)"
		class="rounded-xl bg-white p-5 active:bg-white-shade"
	>
		<div class="mb-1 flex justify-between gap-3 font-medium">
			<div class="w-full">
				<span class="mr-2 break-words">{{ order.name }}</span>
				<span class="whitespace-nowrap font-normal">{{ removeCountryCode(order.mobile) }}</span>
			</div>
			<div class="flex-shrink-0">{{ time }}</div>
		</div>

		<div class="flex justify-between font-medium">
			<div class="text-sm text-grey">{{ items }}</div>

			<div class="flex-shrink-0">
				<div class="relative text-right text-xs font-normal">{{ new Date(order.createdAt).format('%d-%m-%y') }}</div>
			</div>
		</div>

		<span
			v-if="order.wantedAt != null"
			class="relative mt-1 inline-block rounded bg-green bg-opacity-25 px-2 py-1 text-xs font-medium"
		>
			{{ new Date(order.wantedAt).format('%d-%m-%y') }}
		</span>

		<div
			v-if="order.deletedAt != null"
			class="relative mt-1 inline-block rounded bg-red px-2 py-1 text-xs font-medium text-white"
		>
			Strikað: {{ new Date(order.deletedAt).format('%d-%m-%y') }}
		</div>
		<OrderCommentSection
			v-if="order.comment"
			:comment="order.comment"
		/>
	</a>
</template>

<script>
import OrderCommentSection from '@/components/OrderModal/OrderCommentSection.vue';

export default {
	name: 'Order',

	emits: ['click'],

	components: {
		OrderCommentSection,
	},

	props: {
		order: {
			type: Object,
		},
	},

	data() {
		return {
			interval: null,
			now: null,
		};
	},

	mounted() {
		this.now = Date.now();

		this.interval = setInterval(() => {
			this.now = Date.now();
		}, 1000);
	},

	unmounted() {
		clearInterval(this.interval);
	},

	computed: {
		time() {
			let date = new Date(this.order.createdAt);

			// if the order is completed, return the time of completion
			if (this.order.completedAt) {
				return new Date(this.order.completedAt).format('kl. %H:%M');
			}

			// if the order is accepted, return the time left based on expectedCompletionTimeAt
			if (this.order.expectedCompletionTimeAt) {
				const expectedCompletionTimeAt = new Date(this.order.expectedCompletionTimeAt);
				const { minutes } = new Date(this.now).timeDiff(expectedCompletionTimeAt);

				if (minutes < 0) {
					return `${Math.abs(minutes)} min. síðan`;
				}

				// if time is greater than one hour, return expectedCompletionTimeAt HH:mm
				if (minutes > 60) {
					return expectedCompletionTimeAt.format('kl. %H:%M');
				}

				return `${minutes} min.`;
			}

			// if the order is wanted at a certain time, return that time
			if (this.order.wantedAt) {
				return new Date(this.order.wantedAt).format('kl. %H:%M');
			}

			const { hours, minutes } = date.timeDiff(new Date(this.now));

			if (hours >= 1) {
				return `${hours} t.`;
			}

			if (minutes < 1) {
				return `< 1 min.`;
			}

			return `${minutes} min.`;
		},

		items() {
			let strings = [];
			for (const item of this.order.data.items) {
				if (item.type === 'offer') {
					strings = [...strings, `${item.offer.name} (x${item.amount})`];
				}

				if (item.type === 'product') {
					strings = [...strings, `${item.product.name} (x${item.amount})`];
				}
			}

			return strings.join(', ');
		},

		isCritical() {
			if (!this.order.acceptedAt) {
				return false;
			}

			if (this.order.completedAt) {
				return false;
			}

			const expectedCompletionTimeAt = new Date(this.order.expectedCompletionTimeAt);
			const { minutes } = new Date(this.now).timeDiff(expectedCompletionTimeAt);

			return minutes < 0;
		},
	},

	methods: {
		removeCountryCode(phoneNumber) {
			return phoneNumber.startsWith('+298') ? phoneNumber.slice(4) : phoneNumber;
		},
	},
};
</script>
